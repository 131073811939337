import React, {useState} from 'react'
import { Editor } from '@tinymce/tinymce-react';

const Tinymce = props => {

  const [content, setContent] = useState(props.content)

  let toolbar_buttons = 'undo redo | cut copy paste | bold italic underline blockquote | bullist numlist | link | code help'

  if(props.extended)
    toolbar_buttons = 'undo redo | cut copy paste | styles | bold italic underline blockquote | bullist numlist | link | image media | visualblocks code help'

  if(props.pages)
    toolbar_buttons = 'undo redo | cut copy paste | styles | bold italic underline blockquote | bullist numlist | link | image media | visualblocks code help'

  if(props.minimal)
    toolbar_buttons = ''

  return <div style={{width: '79%', marginLeft: '20%'}}>
    <input type="hidden" name={props.name} value={content} />
    <Editor
     tinymceScriptSrc="/tinymce/tinymce.min.js"
     apiKey="akkadfn2r13cdt4d89n2v9o78m2wgdl383vkon4h8v2wkfvg"
     initialValue={props.content}
     init={{
       height: props.height,
       menubar: false,
       inline: props.minimal,
       file_picker_types: 'image',
       image_title: true,
       image_caption: true,
       file_picker_callback: function (cb, value, meta) {
        var input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.onchange = function () {
          var file = this.files[0];
          var reader = new FileReader();
          reader.onload = function () {
            var id = 'blobid' + (new Date()).getTime();
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };
        input.click();
      },
       formats: {
          subtitle1: { block: 'h2' },
          subtitle2: { block: 'h3' }
        },
        style_formats: [
          { title: 'Header 1', format: 'subtitle1' },
          { title: 'Header 2', format: 'subtitle2' }
      ],
       plugins: [
         'image', 'link', 'code', 'lists', 'advlist', 'wordcount', 'media', 'help', 'visualblocks', 'quickbars', 'anchor',

       ],
       quickbars_selection_toolbar: props.minimal ? 'bold italic styles' : 'bold italic styles | quicklink blockquote',
       quickbars_insert_toolbar: false,
       quickbars_image_toolbar: false,
       toolbar: toolbar_buttons
     }}
     onEditorChange={(editorContent) => setContent(editorContent)}
   />
   </div>
}
export default Tinymce
