require('jquery.cookie')
require('lightbox2')
import "../../../node_modules/lightbox2/dist/css/lightbox.min.css"

$(document).ready(function() {

  $('.cookies button').click(function(e) {
    $.cookie('yihr_cookie_consented', 'true', { path: '/', expires: 365 });
    $('.cookies').fadeOut("slow");
  });

  $(".plus_button").click(function() {
    $(this).toggleClass("active");
    $(this).next().slideToggle();
  });


  // Hamburger icon and main navigation
  $(".hamburger").click(function () {
    $("body").toggleClass('navigation_open');
    $(".hamburger").toggleClass('is-active');
  });


  // Accessibility button
  $(".accessibility").click(function () {
    $("body").toggleClass('accessibility_menu');
    $(".accessibility").toggleClass('active');
  });

  // Accessibility - Contrast
  $(".contrast").click(function () {
    $("body").toggleClass('contrast_active');
    // Change header logo to dark
    if ($(".logo").attr("src")  == "/assets/yihr_logo_negative.svg" ) {
      $(".logo").attr("src","/assets/yihr_logo.svg");
    } else {
      $(".logo").attr("src","/assets/yihr_logo_negative.svg");
    }
    // Change footer logo to dark
    if ($(".logo_footer").attr("src")  == "/assets/yihr_logo_mono_negative.svg" ) {
      $(".logo_footer").attr("src","/assets/yihr_logo_mono.svg");
    } else {
      $(".logo_footer").attr("src","/assets/yihr_logo_mono_negative.svg");
    }
  });
  // Accessibility - Dyslexia
  $(".dyslexia").click(function () {
    $("body").toggleClass('dyslexia_active');
  });
  // Accessibility - Underlined links
  $(".underlined_links").click(function () {
    $("body").toggleClass('underlined_links_active');
  });
});
